import util from "lib/util"
import encryptPhoneMixin from 'lib/encryptPhoneMixin'

export default {
  name: "detail",
  mixins: [encryptPhoneMixin],
  data() {
    return {
      auth: true,
      showUnpleased: false,
      finalComment: [],
      commentText: '',
      commentList: [{name:'穿着/谈吐不得体',value:false},{name:'服务态度恶劣',value:false},{name:'没有按时上门',value:false},{name:'多/乱收费',value:false},{name:'乱回填上门时间',value:false},{name:'电话索要取件码',value:false}],
      order: {},
      courier: {},
      supercust: {
        name: '',
        phone: ''
      },
      statusText: '',
      expid: util.query("expid"),
      sign: util.query("sign"),
      tipText: '',
      status: '',
      evaluate: {
        show: false,
        offset: '100%',
        hasDo: false,
        val: -1,
        answer: -1,
        question: ""
      },
      time: 0
    }
  },
  methods: {
    goRealname() {
      this.navigator.push({
        name: "auth"
      })
    },
    finishComment () {  // 点击完成（不满意时）
      var arr = []
      if(!this.finalComment.length && !this.commentText) { // 没评价
        return this.$toast("请选择您不满意的原因")
      } else {
        if(this.finalComment.length) {
          arr = this.finalComment.slice(0)
        }
        if(this.commentText) {
          arr.push(this.commentText.replace(/,/g, '，'))
        }
        this.comments = arr
        this.evaluateCourier()
      }
    },
    toggleComment (index) { // 点击不满意的标签
      this.commentList[index].value = !this.commentList[index].value
    },
    getOrder() {
      this.$http.get('/apicenter/order.do?method=getOrderInfo', {
        data: {
          expid: this.expid,
          sign: this.sign,
          apiversion: 14
        }
      }).then(result => {
        this.loading.hide()
        this.order = result.detail
        this.courier = result.mktInfo
        this.supercust = {
          name: result.supercustname,
          phone: result.supercustcontact
        }
        this.calcStatus()
        this.getQuestion()
      }).catch(() => {
        this.loading && this.loading.hide()
      })
    },
    calcStatus() {
      var order = this.order
      var duration = Math.floor((Date.now() - (+order.createtime)) / 1000) / 60 //下单至今的分钟数
      if(order.role === "THIRD" && order.paystatus === "WAITPAY") {
        this.statusText = '订单金额<b>' + (+this.order.price) + '</b>元，请您核对'
        this.tipText = '点击支付按钮可查看详细价格'
        this.status = 7
        return
      }
      switch (order.tabId) {
        case 'CHECKWAIT': //待审核
          this.statusText = '待审核'
          this.tipText = '您的订单正在等待公司管理员审核'
          this.status = 1
          break
        case 'UNPASS': //审核不通过
          this.statusText = '审核不通过'
          this.tipText = '如有疑问，请联系公司管理员' + this.supercust.name
          this.status = 2
          break;
        case 'PRINTWAIT': //下单成功
          if (order.paystatus == 'WAITPAY' && order.sentunit != 'COMPANY') {
            this.statusText = '订单金额<b>' + (+order.price) + '</b>元，请您核对'
            this.tipText = '点击支付按钮可查看详细价格'
            this.status = 7
          } else {
            this.statusText = '下单成功'
            this.tipText = this.courier.visitService ? '您的订单正在处理中，如果您对收件有要求，请电话联系快递员' : '该快递员不提供上门取件服务，请自行到店里寄件'
            this.status = this.courier.visitService && duration > +this.courier.visitTime || duration > 1440 ? 10 : 3 //若超时显示取件按钮
          }
          break
        case 'GOTWAIT': //已接单
          if (order.paystatus == 'WAITPAY' && order.sentunit != 'COMPANY') {
            this.statusText = '订单金额<b>' + (+order.price) + '</b>元，请您核对'
            this.tipText = '点击支付按钮可查看详细价格'
            this.status = 7
          } else {
            this.statusText = '快递员已接单'
            this.tipText = '请保持电话畅通，以便取件时联系'
            this.status = this.courier.visitService && duration > +this.courier.visitTime || duration > 1440 ? 10 : (order.kuaidiNum ? 4 : 12)
          }
          break
        case 'STAMPINPUTWAIT': //邮票待入库
          this.statusText = '订单已完成'
          this.tipText = '快递单未打印，请联系快递员打印快递单号跟踪物流信息'
          this.status = 11
          break;
        case 'CANCEL': //已取消
          this.statusText = order.canceltype == 'courier' ? '快递员已取消您的订单' : '您的订单已取消'
          this.tipText = order.cancelmsg ? '取消原因：' + order.cancelmsg : '感谢您的支持，期待再次为您服务'
          this.status = 5
          break;
        default: //下单完成
          this.status = +order.iseval ? 9 : order.paystatus == 'PAYED' ? 8 : 6
          this.statusText = order.iseval ? '订单已完成' : (this.status == 8 && order.gotcourier ? '订单已完成支付' : '订单已完成')
          this.tipText = order.iseval || !order.gotcourier ? '感谢您的支持，期待再次为您服务' : '感谢您的支持，请您对本次服务进行评价'
      }
      this.calcTime()
    },
    calcTime() {
      var create = +this.order.createtime
      var now = Date.now()
      this.time = Math.max(Math.floor((now - create) / 1000), 0)
      this.timer && clearInterval(this.timer)
      if ([1, 2, 3, 4, 7, 10, 12].indexOf(this.status) != -1) {
        this.timer = setInterval(() => {
          this.time++
        }, 1000)
      }
    },
    goExpress() {
      let coname = this.$store.state.globalData.coname
      var url = coname ? '/app/query/?coname=' + coname + '&' : "/result.jsp?"
      if (this.order.kuaidiNum) {
        location.href = url + 'nu=' + this.order.kuaidiNum + '&com=' + this.order.kuaidiCom
      } else {
        this.$confirm({
          content: '查看单号或跟踪物流状态，需要快递员在系统中录入单号才可实现，请联系快递员获取',
          confirmText: '联系快递员'
        }, () => {
          this.callLog()
          location.href = 'tel:' + this.courier.phone
        })
      }
    },
    goReceipt() {
      this.navigator.push({
        name: "normalReceipt",
        query: {
          sign: this.sign,
          expid: this.expid,
          stampid: this.order.stampid
        }
      })
    },
    callLog() {
      this.$http.post('/apicenter/courier.do?method=addlog', {
        data: {
          logtype: 'callcourier',
          expid: this.order.expid,
          sign: this.order.sign,
          calltype: 'after',
          phone: this.courier.phone
        },
        handleFail: false
      })
    },
    getQuestion(){
      if((this.status == 6 || this.status == 8) && !+this.order.iseval){
        this.$http.get("/apicenter/order.do?method=evallist")
          .then(result => {
            this.evaluate.question = result.data
            this.toggleEvaluate()
          })
      }
    },
    doEvaluate(v) { // 选择满意或者不满意
      this.evaluate.val = v
      v == 1 ? this.evaluateCourier() : this.evaluate.hasDo = true
    },
    evaluateCourier () { // 新评价接口
      var data = {
        expid: this.expid,
        dispatchid: 0,
        flag1: this.evaluate.val
      }
      this.evaluate.val == 1 ? null : data.comments = this.comments.join(',')
      this.$http.post("/apicenter/order.do?method=evaluateTxtCourier", {
        data: data
      }).then(() => {
        if(this.evaluate.val == 1) { // 满意
          this.$toast("感谢您的评价，期待再次为您服务")
        } else {
          this.showUnpleased = true
          this.$toast("感谢您的反馈，我们会更加努力提升服务")
        }
        this.getOrder()
        this.toggleEvaluate()
      })
    },
    toggleEvaluate() {
      if (this.evaluate.show) {
        this.evaluate.offset = '100%'
        this.evaluate.val = -1
        this.evaluate.hasDo = false
        setTimeout(() => {
          this.evaluate.show = false
        }, 300)
      } else {
        this.evaluate.show = true
        setTimeout(() => {
          this.evaluate.offset = 0
        }, 0)
      }
    },
    goPay() {
      var urlpart = this.courier.type == 'PERSONAL' ? 'openMkt' : ""
      this.navigator.push({
        name: "normalPay",
        query: {
          expid: this.order.id,
          sign: this.sign,
          urlpart: urlpart,
          kuaidicom: this.order.kuaidiCom
        },
        params: {
          source: 'normalDetail'
        }
      })
    },
    goAdd() {
      if (+this.courier.kdrec == 0 && this.order.role !== "THIRD") {
        this.$confirm({
          content: '该快递员最近30天收件质量低于平台要求，是否继续下单',
          confirmText: "继续下单",
          cancelText: "重新选择"
        }, () => {
          this.confirmAdd()
        })
      } else {
        this.confirmAdd()
      }
    },
    confirmAdd() {
      var data = {
        send: {
          name: this.order.sendName,
          phone: this.order.sendmobile,
          address: this.order.sendaddr,
          xzqName: this.order.sendxzq.split('#').join(',')
        },
        rec: {
          name: this.order.recName,
          phone: this.order.recmobile,
          address: this.order.recaddr,
          xzqName: this.order.recxzq.split('#').join(',')
        }
      }
      util.setSession("reOrderAddress", data)
      util.setSession('kd_sentcom', this.order.kuaidiCom);
      this.order.department && util.setSession('kd_department', this.order.department)
      this.order.gotaddr && util.setSession('kd_gotaddr', this.order.gotaddr)
      this.order.payaccount && util.setSession('kd_payaccount', this.order.payaccount)
      this.navigator.push({
        name: "normal",
        query: {
          sign: this.sign,
          sendAgain: 1
        }
      })
    },
    cancel() {
      this.navigator.push({
        name: "cancel",
        params: {
          order: this.order
        }
      })
    },
    confirmOrder() {
      this.$confirm({
        title: '提示',
        content: '为保障您的权益，请尽可能的获取单号后，再确认取件（您可联系快递员获取单号）。',
        confirmText: '确认取件'
      }, () => {
        this.loading = this.$loading("正在确认取件...")
        this.$http.post('/apicenter/order.do?method=submitGot', {
          data: {
            expid: this.order.id
          }
        }).then(result => {
          if (result.status) {
            this.$toast('操作成功')
            this.loading.hide()
            this.status = 6
            this.statusText = '订单已完成'
            this.tipText = '感谢您的支持，期待再次为您服务'
          } else {
            this.$toast('确认取件失败，请稍后再试')
            this.loading.hide()
          }
        })
      })
    },
    getAuth: function() {
      this.$http.get("/apicenter/order.do?method=queryUserCardInfo").then(result => {
        if (result.data) {
          this.auth = true
        } else {
          this.auth = false
        }
      })
    },
    appGuide() {
      AppGuide.dialog({
        title: "物流信息实时追踪提醒",
        logtype: 'orderDetail',
        iosLink: "ilovegirl/near?selectIndex=2&ordersource=mweb",
        androidLink: "ilovegirl/near?source=mweb",
        miniprogram: {
          path: "pages/orderindex/index",
          orderSrouce: "mweb"
        },
        plainBtn: {
          text: "继续使用"
        }
      })
    }
  },
  computed: {
    tictor: function() {
      var hour = Math.floor(this.time / 3600)
      var minute = Math.floor(this.time % 3600 / 60)
      var second = Math.floor(this.time % 60)
      return [hour, minute, second]
    },
    cargoInfo: function() {
      var str = this.order.cargo
      this.order.count && (str += ' ' + this.order.count + '件')
      this.order.weight && (str += ' ' + this.order.weight + 'kg')
      return str
    }
  },
  watch:{
    commentList: {
      handler:function(newVal){
        var arr = []
        for(var i in newVal) {
          if(newVal[i].value) {
            arr.push(newVal[i].name)
          }
        }
        this.finalComment = arr
      },
      deep:true
    },
    'evaluate.answer': function(val) {
      if(val == -1) return
      this.$http.post("/apicenter/order.do?method=evaluateCourier", {
        data: {
          expid: this.order.expid,
          sign: this.order.sign,
          flag1: this.evaluate.val,
          flag2: this.evaluate.answer,
          questionid: this.evaluate.question.id
        }
      }).then(() => {
        this.$toast("感谢您的评价，期待再次为您服务")
        this.getOrder()
      })
    }
  },
  created() {
    this.toggleEvaluate()
    if (this.expid) {
      this.login(() => {
        this.loading = this.$loading('正在加载...')
        this.getOrder()
        this.getAuth()
      }, () => {
        this.goLogin()
      })
    } else {
      this.navigator.replace({
        name: "orderList"
      })
    }
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  }
}